import Vue from 'vue';
import { Form, Field, Toast } from 'vant';

import { PHONE, PWD } from '../../utils/regExp';
import { getCheckCode, register } from '../../api/registerApi';


Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);

export default {
  components: {},
  data() {
    return {
      userPhone: '',
      code: '',
      userPassword: '',
      rePassword: '',
      show: true,
      count: '',
      timer: null,
    };
  },
  methods: {
    // 倒计时
    cutDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            // eslint-disable-next-line no-plusplus
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    onClickCheckCode() {
      this.cutDown();
      const phone = this.userPhone;
      if (phone !== '') {
        const checkPh = phone.replace(PHONE, '');
        if (checkPh !== '') {
          Toast('请输入正确手机号');
        } else {
          getCheckCode({ phone }).then((res) => {
            if (res.code === 200) {
              Toast('验证码已发送，请注意查收');
            }
          });
        }
      } else {
        Toast('手机号不能为空');
      }
    },
    onSubmit(values) {
      if (values.userPassword === values.rePassword) {
        if (PWD.test(values.userPassword)) {
          const checkPh = this.userPhone.replace(PHONE, '');
          if (checkPh !== '') {
            Toast('请输入正确手机号');
          } else {
            // values.standby2=localStorage.getItem('openId')
            register(values).then((res) => {
              if (res.code === 200) {
                this.$router.push({ path: '/login' });
              }
            });
          }
        } else {
          Toast('密码需满足6-24位数字，字母，特殊字符');
        }
      } else {
        Toast('密码和确认密码不一致，请重新输入');
      }
    },

  },
};
