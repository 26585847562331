/**
 * 验证 邮箱
 * 提示: 邮箱格式不正确
 */
export const EMAIL = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

/**
 * 验证 手机号,
 * 提示: 手机格式不正确
 */
export const PHONE = /^134[0-8]\d{7}$|^13[^4]\d{8}$|^14[5-9]\d{8}$|^15[^4]\d{8}$|^16[6]\d{8}$|^17[0-8]\d{8}$|^18[\d]{9}$|^19[8,9]\d{8}$/;

/**
 * 验证 密码
 * 提示: 至少包含数字/字母/字符2种组合,长度至少为6个字符
 */
export const PASSWORD = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![a-z]+$)(?![!@#$%^&*=]+$)[0-9A-Za-z!@#$%^&*=]{6,}$/;

/**
 * 验证 密码
 * 提示: 6-24位数字，字母，特殊字符即6-24位不能有中文的密码
 */
export const PWD = /^[^\u4e00-\u9fa5]{6,24}$/;
/**
 * 验证 收货人
 * 提示: 2到20个汉字或字符串之间
 */
export const  People = /^.{2,20}$/ ;
/**
 * 验证 地址
 * 提示: 详细地址长度需在5-120字符之间
 */
export const  Address = /^.{5,120}$/ ;

